import apiClient from '@/_shared/services/apiClient';
import { Client, ClientForReports } from '@/_shared/types/client';

type ClientApiResponse = {
  client: Client
}

export const postNewClient = async (ouId: number, client: Client): Promise<ClientApiResponse | null> => {
  if (client.birthDate === 'not_known') delete client.birthDate;
  if (client.startOn === 'not_known') delete client.startOn;
  const url = `clients?ou_id=${ouId}`;
  try {
    return await apiClient.post(url, { client });
  } catch (error) {
    return null;
  }
};

type ClientTemplatesApiResponse = {
  clients: Client[]
}

export const getClientTemplates = async (ouId: number): Promise<ClientTemplatesApiResponse | null> => {
  const url = `organisation_units/${ouId}/clients?always_show_template=true&per_page=100&`
    + 'hide_template_if_no_access=false&include_health_profile=true&state%5B%5D=template';
  try {
    return await apiClient.get(url);
  } catch (error) {
    return null;
  }
};

type ClientOrganisationUnitForReports = {
  id: number,
  personId: number,
  organisationUnitId: number,
  state: string
};
type ClientOrganisationUnitForReportsResponse = {
  clientOrganisationUnits: ClientOrganisationUnitForReports[]
};
type ClientNameForReports = {
  id: number,
  firstName: string,
  lastName: string,
  name: string,
  prefferedName: string
};
type ClientNameForReportsResponse = {
  people: ClientNameForReports[]
};
export const getClientsForReports = async (ouId: number): Promise<ClientForReports[] | null> => {
  const url = `/api/v2/organisation_units/${ouId}/client_organisation_units_for_reports`;
  try {
    const response: ClientOrganisationUnitForReportsResponse = await apiClient.get(url);
    const { clientOrganisationUnits } = response;
    const clientIds = clientOrganisationUnits.map((c) => c.personId);
    const peopleUrl = '/api/v2/people/people_for_reports';
    const clientsResponse: ClientNameForReportsResponse = await apiClient.post(peopleUrl, { person: { ids: clientIds } });
    const clients = clientsResponse.people;
    const clientStatesHash = clientOrganisationUnits.reduce((hash, clientOrganisationUnit) => {
      hash[clientOrganisationUnit.personId] = clientOrganisationUnit.state;
      return hash;
    }, {} as Record<number, string>);
    return clients.map((c) => ({
      id: c.id,
      displayName: c.name,
      state: clientStatesHash[c.id],
    }));
  } catch (error) {
    return null;
  }
};
