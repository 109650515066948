import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots } from "vue"
import _imports_0 from '../../assets/calendar.svg'


const _hoisted_1 = { class: "v-input-wrapper" }
const _hoisted_2 = ["onClick", "onKeyup"]
const _hoisted_3 = { class: "v-clickable-text" }
const _hoisted_4 = { class: "actions" }

import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import {
  computed,
  onBeforeMount,
  PropType, ref, watch, watchEffect,
} from 'vue';
import VueDatepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { useInputMethods } from '@/_shared/helpers/useInputMethods';
import use from '@/_shared/compositionApi';
import {
  checkIfDateGreaterThanDays,
  formatTimezoneDate,
} from '@/_shared/helpers/useDateTimeHelper';

import {
  previousMonday, previousSunday,
  subDays,
} from 'date-fns';

type ModelType = 'date' | 'dateTime' | 'time';

// TODO add from and to to time picker
// TODO box shadow on popover


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDatePickerInput',
  props: {
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Date, Array, null] as PropType<string | Date | Date[] | null>,
    required: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  labelWidth: {
    type: String,
    default: undefined,
  },
  boldLabel: {
    type: Boolean,
    default: undefined,
  },
  inputWidth: {
    type: String,
    default: '100%',
  },
  icon: {
    type: String,
    default: undefined,
  },
  type: {
    type: String as PropType<ModelType>,
    default: 'date',
  },
  is24Hr: {
    type: Boolean,
    default: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  noIcon: {
    type: Boolean,
    default: false,
  },
  autoApply: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  dateFormat: {
    type: String,
    required: false,
  },
  customTriggerInput: {
    type: String,
    required: false,
  },
  position: {
    type: String as PropType<'left' | 'center' | 'right'>,
    default: 'center',
  },
  minDate: {
    type: [String, Date],
    default: null,
  },
  maxDate: {
    type: [String, Date],
    default: null,
  },
  showDateExceedsToast: {
    type: Boolean,
    default: false,
  },
  standardInputStyle: {
    type: Boolean,
    default: false,
  },
  showPresets: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:modelValue', 'changeDate'],
  setup(__props, { emit: __emit }) {

const props = __props;

const range = computed(() => Array.isArray(props.modelValue));

const emit = __emit;
const { toast, ToastType } = use.helpers();

onBeforeMount(() => {
  if (range.value && Array.isArray(props.modelValue)) {
    localValue.value = props.modelValue.map((date: Date) => new Date(date));
  } else if (props.modelValue && !Array.isArray(props.modelValue)) {
    localValue.value = new Date(props.modelValue);
  }
});
const localValue = ref<Date | Date[] | null >(null);
const localValueSetup = ref(false);

onBeforeMount(() => {
  if (range.value && Array.isArray(props.modelValue)) {
    localValue.value = props.modelValue.map((date: Date) => new Date(date));
  } else if (props.modelValue && !Array.isArray(props.modelValue)) {
    localValue.value = new Date(props.modelValue);
  }
  localValueSetup.value = true;
});

watch(() => props.modelValue, (newValue) => {
  if (!newValue) {
    localValue.value = range.value ? [] : null;
    return;
  }
  if (range.value && Array.isArray(newValue)) {
    localValue.value = newValue.map((date) => new Date(date));
  } else if (props.modelValue && !Array.isArray(newValue)) {
    localValue.value = new Date(newValue);
  }
});

const dp = ref();

const updateDate = () => {
  let newVal: string | string[] | null = range.value ? [] : null;
  if (props.type === 'date' && localValue.value) {
    if (Array.isArray(localValue.value)) {
      newVal = localValue.value.map((date) => formatTimezoneDate(date));
    } else {
      newVal = formatTimezoneDate(localValue.value);
    }
  } else if (props.type === 'dateTime' && localValue.value) {
    if (Array.isArray(localValue.value)) {
      newVal = localValue.value.map((date) => date.toISOString());
    } else {
      newVal = localValue.value.toISOString();
    }
  }
  if (newVal === props.modelValue) {
    return;
  }
  if (Array.isArray(newVal) && newVal.toString() === props.modelValue?.toString()) {
    return;
  }

  emit('update:modelValue', newVal);
};

watchEffect(() => {
  if (!localValueSetup.value) return;
  updateDate();
});

watch(() => localValue.value, (newValue, oldValue) => {
  if (!newValue || !oldValue) {
    return;
  }
  let newDates: Date[] = [];
  let oldDates: Date[] = [];
  if (!range.value) {
    newDates = [newValue as Date];
    oldDates = [oldValue as Date];
  }
  newDates.forEach((date, index) => {
    if (date?.getTime() === oldDates[index]?.getTime()) {
      return;
    }
    if (props.showDateExceedsToast && checkIfDateGreaterThanDays(date, 20)) {
      toast(translate('common.greater_than_one_month'), ToastType.Info);
    }
  });
});

const selectDate = () => {
  dp.value.selectDate();
  updateDate();
  emit('changeDate', localValue.value);
};

const {
  inputStyle, labelClass, labelStyle,
} = useInputMethods(props);

const { locale, translate } = use.helpers();

const presets = ref([{ label: translate('inputs.timepicker.today'), range: [new Date(), new Date()] },
  { label: translate('inputs.timepicker.yesterday'), range: [subDays(new Date(), 1), subDays(new Date(), 1)] },
  {
    label: translate('inputs.timepicker.last_7_days'),
    range: [subDays(new Date(), 7), subDays(new Date(), 1)],
  },
  { label: translate('inputs.timepicker.last_week'), range: [previousMonday(subDays(new Date(), 6)), previousSunday(new Date())] }]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          style: _normalizeStyle(_unref(labelStyle)()),
          class: _normalizeClass(_unref(labelClass)())
        }, _toDisplayString(__props.label), 7))
      : _createCommentVNode("", true),
    (__props.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 1,
          class: _normalizeClass(__props.icon)
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["v-datepicker-container", __props.noIcon ? '' : 'v-date-icon']),
      style: _normalizeStyle(_unref(inputStyle)())
    }, [
      _createVNode(_unref(VueDatepicker), {
        ref_key: "dp",
        ref: dp,
        class: "v-datepicker",
        modelValue: localValue.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localValue).value = $event)),
        onCleared: selectDate,
        is24Hr: __props.is24Hr,
        format: __props.type === 'dateTime' ? 'dd/MM/yyyy, H:mm' : 'dd/MM/yyyy',
        locale: _unref(locale),
        placeholder: __props.placeholder,
        disabled: __props.readOnly,
        hideInputIcon: __props.noIcon,
        enableTimePicker: props.type !== 'date',
        "select-attribute": {highlight: {class:'v-selected-date', contentClass:'v-selected-date'}},
        textInput: "",
        "auto-apply": __props.autoApply,
        clearable: __props.clearable,
        position: __props.position,
        "min-date": __props.minDate,
        "max-date": __props.maxDate,
        range: range.value,
        "preset-ranges": __props.showPresets ? presets.value : undefined
      }, _createSlots({
        "preset-date-range-button": _withCtx(({ label, value, presetDate }) => [
          _createElementVNode("span", {
            role: "button",
            tabindex: 0,
            onClick: ($event: any) => (presetDate(value)),
            onKeyup: [
              _withKeys(_withModifiers(($event: any) => (presetDate(value)), ["prevent"]), ["enter"]),
              _withKeys(_withModifiers(($event: any) => (presetDate(value)), ["prevent"]), ["space"])
            ]
          }, _toDisplayString(label), 41, _hoisted_2)
        ]),
        "clock-icon": _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(translate)('common.select_time')), 1)
        ]),
        "calendar-icon": _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(translate)('common.select_date')), 1)
        ]),
        "input-icon": _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            class: "input-icon",
            alt: "",
            src: _imports_0
          }, null, -1))
        ]),
        "action-select": _withCtx(() => [
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("a", {
              class: "cancel-action",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (dp.value.closeMenu && dp.value.closeMenu(...args)))
            }, _toDisplayString(_unref(translate)('common.cancel')), 1),
            _createVNode(BaseButton, {
              size: "small",
              onClicked: _cache[1] || (_cache[1] = ($event: any) => (selectDate()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(translate)('common.save')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 2
      }, [
        (__props.customTriggerInput)
          ? {
              name: "trigger",
              fn: _withCtx(() => [
                _cache[3] || (_cache[3] = _createElementVNode("i", { class: "nr-icon-calendar" }, null, -1)),
                _createElementVNode("span", _hoisted_3, _toDisplayString(__props.customTriggerInput), 1)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["modelValue", "is24Hr", "format", "locale", "placeholder", "disabled", "hideInputIcon", "enableTimePicker", "auto-apply", "clearable", "position", "min-date", "max-date", "range", "preset-ranges"])
    ], 6)
  ]))
}
}

})