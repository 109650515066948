import { computed, ComputedRef } from 'vue';
import apiClient from '../services/apiClient';
import { Person, PersonQueryResponse, PersonStore } from './people';

export interface Carer extends Person {
  email: string;
  activeOrganisationUnitIds: number[];
  serviceIds?: number[];
  currentOrganisationUnitState?: string;
}

export interface CarerQueryResponse extends PersonQueryResponse {
  carers: Carer[];
}

interface CarerFetchParams {
  state?: string;
}

class CarerStore extends PersonStore<Carer, CarerQueryResponse> {
  initialLoad(): Promise<CarerQueryResponse> {
    return this.fetchAll();
  }

  fetchAll(orgUnitId: number | null = null, activeOnly = true): Promise<CarerQueryResponse> {
    if (orgUnitId == null) {
      orgUnitId = window.currentOrganisationUnit.id as number;
    }
    const url = `/api/v2/organisation_units/${orgUnitId}/carers`;

    const params: CarerFetchParams = {};

    if (activeOnly) {
      params.state = 'active';
    }

    // TODO: Need carer api
    return apiClient.get(url, { params });
  }

  fetch(ids: number[]): Promise<CarerQueryResponse> {
    return apiClient.post('/api/v2/carers/query', {
      query: { ids },
      ouId: window.currentOrganisationUnit.id,
    });
  }

  extractData(data: CarerQueryResponse): Carer[] {
    return data.carers;
  }

  carers = (ids: number[]) => ids.map((id) => this.byId(id));

  all = () => computed(() => <Carer[]>Object.values(this.collection));

  active = (orgUnitId: number) => computed(() => this.all().value.filter((carer: Carer) => carer.activeOrganisationUnitIds?.includes(orgUnitId)));

  displayName = (id: number): ComputedRef<string> => computed(() => this.byId(id).name || '');
}

export const carerStore = new CarerStore();
