import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import clientStore from '@/_shared/store/clients';
import InstanceViewTimeline from '../../timeline/components/InstanceViewTimeline.vue';
import EventListEntry from './EventListEntry.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventViewTimeline',
  props: {
  instanceId: {
    type: String,
    required: true,
  },
  clientId: {
    type: String,
    required: true,
  },
  searchTerm: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props;

const selectedInstance = computed(() => clientStore.eventPWSInstances(+props.clientId).value.find((i) => i.id === +props.instanceId));


return (_ctx: any,_cache: any) => {
  return (selectedInstance.value)
    ? (_openBlock(), _createBlock(InstanceViewTimeline, {
        key: 0,
        "instance-id": +props.instanceId,
        "client-id": +props.clientId,
        "search-term": props.searchTerm,
        "selected-instance": selectedInstance.value
      }, {
        default: _withCtx(() => [
          _createVNode(EventListEntry, {
            class: "v-selected-instance",
            instance: selectedInstance.value
          }, null, 8, ["instance"])
        ]),
        _: 1
      }, 8, ["instance-id", "client-id", "search-term", "selected-instance"]))
    : _createCommentVNode("", true)
}
}

})