import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "v-checkboxes" }

import { computed, PropType } from 'vue';
import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import { CompositeOption } from '@/_shared/types/baseSelect';
import { useInputMethods } from '@/_shared/helpers/useInputMethods';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCheckboxGroup',
  props: {
  modelValue: {
    type: [Array, String, Boolean] as PropType<string[] | string | boolean>,
    required: true,
  },
  options: {
    // eslint-disable-next-line no-undef
    type: Array as PropType<CompositeOption[]>,
    required: true,
  },
  light: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Boolean,
    default: false,
  },
  blackTick: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
  // standardInputStyle is the new standardised way that we show inputs. We can slowly move across to this
  standardInputStyle: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isMultipleChoice = computed(() => Array.isArray(props.modelValue));

const isSingleCheckbox = computed(() => props.options.length === 1);

const checkboxChanged = (optionValue: string) => {
  let newValue: string | string[] | boolean;
  if (isSingleCheckbox.value) {
    newValue = !props.modelValue;
  } else if (isMultipleChoice.value) {
    if ((props.modelValue as string[]).find((value) => value === optionValue)) {
      newValue = (props.modelValue as string[]).filter((value) => value !== optionValue);
    } else {
      newValue = [...(props.modelValue as string[]), optionValue];
    }
  } else {
    newValue = optionValue;
  }
  emit('update:modelValue', newValue);
};

const isChecked = (option: string) => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(option);
  }
  return props.modelValue === option;
};

const optionToString = (option: string | number): string => (typeof option === 'number' ? option.toString() : option);

const {
  labelClass, labelStyle, wrapperClasses,
} = useInputMethods(props);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(wrapperClasses)())
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          style: _normalizeStyle(_unref(labelStyle)()),
          class: _normalizeClass(_unref(labelClass)())
        }, _toDisplayString(__props.label), 7))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
        return (_openBlock(), _createBlock(BaseCheckbox, {
          key: option.value,
          disabled: option.disabled,
          label: option.text,
          light: __props.light,
          center: __props.center,
          blackTick: __props.blackTick,
          radio: (!isMultipleChoice.value && !isSingleCheckbox.value),
          checked: isChecked(optionToString(option.value)),
          "onUpdate:checked": () => checkboxChanged(optionToString(option.value))
        }, null, 8, ["disabled", "label", "light", "center", "blackTick", "radio", "checked", "onUpdate:checked"]))
      }), 128))
    ])
  ], 2))
}
}

})