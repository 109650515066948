import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-params-entry-info-col"
}

import { ref, computed } from 'vue';
import use from '@/_shared/compositionApi';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { IconColor } from '@/_shared/types/iconColor';
import { ParameterState } from '../types/Parameter';

interface Props {
  parameterState?: ParameterState,
  showStatus?: boolean,
}

interface IconData {
  highlightColor: string;
  iconColor: IconColor;
  icon: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ParameterIcon',
  props: {
    parameterState: {},
    showStatus: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { translate } = use.helpers();

const iconData = ref<{[key: string]: IconData}>(
  {
    conflict: {
      highlightColor: '#66cccc', iconColor: 'teal', icon: 'v-icon-conflict',
    },
    prefilled: {
      highlightColor: 'grey', iconColor: 'grey', icon: 'nsx-font-icon--help',
    },
    error: {
      highlightColor: '#D10711', iconColor: 'alarm', icon: 'v-icon-conflict',
    },
  },
);

const highlightStyle = computed(() => [
  {
    '--highlight-color': props.parameterState && props.showStatus !== false
      ? iconData.value[props.parameterState]?.highlightColor
      : '',
  },
]);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "highlighter highlighted-edge",
    style: _normalizeStyle(highlightStyle.value)
  }, [
    (props.parameterState && props.showStatus !== false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(BaseTooltip, {
            position: "right",
            class: "v-icon-tooltip",
            tooltipText: _unref(translate)(`parameter.${props.parameterState}`)
          }, {
            default: _withCtx(() => [
              _createVNode(BaseIcon, {
                name: iconData.value[props.parameterState].icon,
                class: _normalizeClass(`sp-${props.parameterState}`),
                foregroundColor: iconData.value[props.parameterState].iconColor,
                noHover: "",
                size: "2x"
              }, null, 8, ["name", "class", "foregroundColor"])
            ]),
            _: 1
          }, 8, ["tooltipText"])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}
}

})