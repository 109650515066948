import apiClient from '@/_shared/services/apiClient';
import { computed } from 'vue';
import { ProvidedService } from '../types/providedService';
import CollectionStore from './collectionStore';

export type ProvidedServiceQueryResponse = {
  providedServices: ProvidedService[];
}

class ProvidedServicesStore extends CollectionStore<ProvidedService, ProvidedServiceQueryResponse> {
  protected initialLoad(): Promise<ProvidedServiceQueryResponse> {
    const url = `/api/v2/organisation_units/${window.currentOrganisationUnit.id}/provided_services`;
    return apiClient.get<ProvidedServiceQueryResponse>(url);
  }

  protected extractData(data: ProvidedServiceQueryResponse): ProvidedService[] {
    return data.providedServices;
  }

  fetch(ids: number[]): Promise<ProvidedServiceQueryResponse> {
    return apiClient.post<ProvidedServiceQueryResponse>('/api/v2/provided_services/query', { query: { ids, include_deleted: true } });
  }

  categoryCodename = (id: number) => computed(() => this.byId(id).categoryCodename || 'loading');

  all = computed(() => Object.values(this.collection));

  getProvidedServicesExcludeDeleted = computed(() => {
    this.initialize();
    return Object.values(this.collection).filter((providedService) => !providedService.deletedAt);
  });

  byCodenames = (codenames: string[]) => computed(() => this.getProvidedServicesExcludeDeleted.value.filter((ps) => ps.codename != null && codenames.includes(ps.codename)));
}

// const providedServicesStore = ProvidedServicesStore.init(
//   new ProvidedServicesStore(),
//   'providedServices',
// );

export const providedServicesStore = new ProvidedServicesStore();
