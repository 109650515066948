import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseCheckboxGroup from '@/_shared/components/inputs/BaseCheckboxGroup.vue';
import { computed, PropType } from 'vue';
import useReportStore from '@/_shared/store/reports';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportPageBreakInput',
  props: {
  term: {
    type: String as PropType<'client' | 'carer'>,
    required: true,
  },
},
  setup(__props) {


const props = __props;

const { newReport } = useReportStore();

const { translate } = use.helpers();

const newPageWithTerm = computed(() => translate('reports.page_break_label')
  + translate(`terms.${props.term}`).toLowerCase());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCheckboxGroup, {
    modelValue: _unref(newReport).params.pageBreak,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).params.pageBreak) = $event)),
    options: [{ value: 1, text: newPageWithTerm.value }],
    label: _unref(translate)('reports.page_break'),
    "standard-input-style": ""
  }, null, 8, ["modelValue", "options", "label"]))
}
}

})