import { ReportClass, Report } from '@/_shared/types/report';
import { DeepPartial } from '@/_shared/types/extended';

export const defaultReport: Report = {
  id: 0,
  title: '',
  format: 'html',
  state: 'draft',
  stateDetail: '',
  params: {
    pageBreak: true,
    pageNumbering: false,
    redactionOption: 'show_all',
    fields: [],
  },
  file: {
    url: '',
  },
  createdAt: new Date(),
  updatedAt: new Date(),
  uuid: null,
  organisationUnitId: 0,
  reportClass: 'ClientProfileReport',
  viewType: 'normal',
};

const defaultClientProfileReport: DefaultReport = {
  reportClass: 'ClientProfileReport',
  params: {
    clientIds: [],
    pageBreak: true,
    pageNumbering: false,
    redactionOption: 'show_all',
    fields: [
      'status_in_organisation',
      'biography',
    ],
  },
};

const defaultClientCareplanReport: DefaultReport = {
  reportClass: 'ClientCareplanReport',
  params: {
    clientIds: [],
    pageBreak: true,
    pageNumbering: false,
    redactionOption: 'show_all',
    fields: [
      'previous_reviews',
    ],
  },
};

const defaultClientInteractionsReport: DefaultReport = {
  reportClass: 'ClientInteractionsReport',
  params: {
    clientIds: [],
    carerIds: [],
    pageBreak: true,
    pageNumbering: false,
    alarmState: ['ok', 'warning', 'alarm'],
    interactionState: ['closed', 'cancelled'],
    redactionOption: 'show_all',
    fields: [
      'note_public',
      'parameters',
      'show_alarms',
      'close_justification',
      'late_alarms',
    ],
    fromDate: new Date(),
    toDate: new Date(),
  },
  viewType: 'normal',
};

const defaultInteractionChangesReport: DefaultReport = {
  reportClass: 'InteractionChangesReport',
  params: {
    clientIds: [],
    providedServiceIds: [],
    pageBreak: true,
    pageNumbering: false,
    redactionOption: 'show_all',
    fromDate: new Date(),
    toDate: new Date(),
    fields: [],
  },
  viewType: 'normal',
};

const defaultCarerProfileReport: DefaultReport = {
  reportClass: 'CarerProfileReport',
  params: {
    carerIds: [],
    pageBreak: true,
    pageNumbering: false,
    redactionOption: 'show_all',
    fields: [],
  },
};

const defaultWoundReport: DefaultReport = {
  reportClass: 'WoundSinglePhotoReport',
  params: {
    clientIds: [],
    pageBreak: true,
    pageNumbering: false,
    redactionOption: 'show_all',
    fields: [],
  },
};

export const defaultReports: Record<ReportClass, DeepPartial<Report>> = {
  ClientCareplanReport: defaultClientCareplanReport,
  ClientInteractionsReport: defaultClientInteractionsReport,
  CarerProfileReport: defaultCarerProfileReport,
  ClientProfileReport: defaultClientProfileReport,
  InteractionChangesReport: defaultInteractionChangesReport,
  WoundMultiPhotoReport: defaultWoundReport,
  WoundSinglePhotoReport: defaultWoundReport,
};

export const getDefaultReport = (reportType: ReportClass, organisationUnitId: number): Report => ({ ...defaultReport, ...defaultReports[reportType], organisationUnitId } as Report);

export type DefaultReport = Partial<Report> & Pick<Report, 'params'>
