import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseCardSection from '@/_shared/components/display/BaseCard/BaseCardSection.vue';
import { BaseInput, BaseSelect } from '@/_shared/components';
import use from '@/_shared/compositionApi';
import useReportStore from '@/_shared/store/reports';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportType',
  setup(__props) {


const { translate } = use.helpers();

const reportStore = useReportStore();
const { newReport } = storeToRefs(reportStore);

const reportTypes = [
  { value: 'ClientCareplanReport', text: translate('reports.types.client_careplan_report') },
  { value: 'InteractionChangesReport', text: translate('reports.types.interaction_changes_report') },
  { value: 'ClientProfileReport', text: translate('reports.types.client_profile_report') },
  { value: 'CarerProfileReport', text: translate('reports.types.carer_profile_report') },
  { value: 'ClientInteractionsReport', text: translate('reports.types.client_interactions_report') },
  { value: 'WoundMultiPhotoReport', text: translate('reports.types.wound_multi_photo_report') },
  { value: 'WoundSinglePhotoReport', text: translate('reports.types.wound_single_photo_report') },
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCardSection, {
    title: _unref(translate)('reports.report')
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseSelect), {
        "standard-input-style": "",
        label: _unref(translate)('reports.types.title'),
        modelValue: _unref(newReport).reportClass,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).reportClass) = $event)),
        options: reportTypes,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_unref(reportStore).buildNewReport($event)))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_unref(BaseInput), {
        "standard-input-style": "",
        label: _unref(translate)('common.title'),
        modelValue: _unref(newReport).title,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(newReport).title) = $event)),
        placeholder: _unref(translate)('common.title')
      }, null, 8, ["label", "modelValue", "placeholder"])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})