import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import BaseCardSection from '@/_shared/components/display/BaseCard/BaseCardSection.vue';
import BaseCheckboxGroup from '@/_shared/components/inputs/BaseCheckboxGroup.vue';
import useReportStore from '@/_shared/store/reports';
import ReportClientInput from '@/reports/inputs/ReportClientInput.vue';
import ReportFormatInput from '@/reports/inputs/ReportFormatInput.vue';
import ReportPageBreakInput from '@/reports/inputs/ReportPageBreakInput.vue';
import use from '@/_shared/compositionApi';
import { CompositeOption } from '@/_shared/types/baseSelect';
import { CareplanFields } from '@/_shared/types/report';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'CareplanReport',
  setup(__props) {

const { translate } = use.helpers();

const { newReport } = useReportStore();

const { featureToggles } = storeToRefs(useUserStore());

const reportSections: CompositeOption<CareplanFields>[] = [
  { value: 'previous_reviews', text: translate('reports.fields.careplan.previous_reviews') },
  { value: 'need_provided_services', text: translate('reports.fields.careplan.linked_interactions') },
  { value: 'interaction_logs', text: translate('reports.fields.careplan.interaction_logs') },
  { value: 'critical_information', text: translate('reports.fields.careplan.critical_information') },

];

if (featureToggles.value.carePlanExtendedReport) {
  reportSections.push(
    { value: 'profile', text: translate('reports.fields.careplan.basic_profile') },
    { value: 'emergency_critical_info', text: translate('reports.fields.careplan.critical_and_emergency_info') },
  );
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.filters.title')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportClientInput)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.fields.title')
    }, {
      default: _withCtx(() => [
        _createVNode(BaseCheckboxGroup, {
          modelValue: _unref(newReport).params.fields,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).params.fields) = $event)),
          options: reportSections,
          label: _unref(translate)('reports.fields.careplan.title'),
          "standard-input-style": ""
        }, null, 8, ["modelValue", "label"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.report_format')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportFormatInput, { formats: ['pdf', 'html'] }),
        _createVNode(ReportPageBreakInput, { term: "carer" })
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}
}

})