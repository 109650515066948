import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import BaseCardSection from '@/_shared/components/display/BaseCard/BaseCardSection.vue';
import { BaseDatePickerInput } from '@/_shared/components';
import use from '@/_shared/compositionApi';
import useReportStore from '@/_shared/store/reports';
import ReportClientInput from '@/reports/inputs/ReportClientInput.vue';
import ReportInteractionInput from '@/reports/inputs/ReportInteractionInput.vue';
import ReportCarerInput from '@/reports/inputs/ReportCarerInput.vue';
import BaseCheckboxGroup from '@/_shared/components/inputs/BaseCheckboxGroup.vue';
import ReportInteractionStateSelect from '@/reports/inputs/ReportInteractionStateSelect.vue';
import ReportAlarmStateSelect from '@/reports/inputs/ReportAlarmStateSelect.vue';
import { NotesFields, WarningsFields } from '@/_shared/types/report';
import { CompositeOption } from '@/_shared/types/baseSelect';
import ReportPageBreakInput from '@/reports/inputs/ReportPageBreakInput.vue';
import ReportFormatInput from '@/reports/inputs/ReportFormatInput.vue';
import ReportDataViewSelect from '@/reports/inputs/ReportDataViewSelect.vue';
import useReportDateHelper from '@/reports/helpers/useReportDateHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimelineReport',
  setup(__props) {

const { translate } = use.helpers();

const { newReport } = useReportStore();

const notesOptions: CompositeOption<NotesFields>[] = [
  { text: translate('reports.fields.notes.note_public'), value: 'note_public' },
  { text: translate('reports.fields.notes.parameters'), value: 'parameters' },
];

const warningsOptions: CompositeOption<WarningsFields>[] = [
  { text: translate('reports.fields.warnings.show_alarms'), value: 'show_alarms' },
  { text: translate('reports.fields.warnings.close_justification'), value: 'close_justification' },
  { text: translate('reports.fields.warnings.late_alarms'), value: 'late_alarms' },
];

const { dateRange } = useReportDateHelper();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.filters.title')
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseDatePickerInput), {
          modelValue: _unref(dateRange),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(dateRange) ? (dateRange).value = $event : null)),
          label: _unref(translate)('reports.filters.date_range'),
          range: "",
          "show-presets": "",
          "standard-input-style": ""
        }, null, 8, ["modelValue", "label"]),
        _createVNode(ReportClientInput),
        _createVNode(ReportInteractionInput),
        _createVNode(ReportInteractionStateSelect),
        _createVNode(ReportAlarmStateSelect),
        _createVNode(ReportCarerInput)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.fields.title')
    }, {
      default: _withCtx(() => [
        _createVNode(BaseCheckboxGroup, {
          modelValue: _unref(newReport).params.fields,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(newReport).params.fields) = $event)),
          options: notesOptions,
          label: _unref(translate)('reports.fields.notes.title'),
          "standard-input-style": ""
        }, null, 8, ["modelValue", "label"]),
        _createVNode(BaseCheckboxGroup, {
          modelValue: _unref(newReport).params.fields,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(newReport).params.fields) = $event)),
          options: warningsOptions,
          label: _unref(translate)('reports.fields.warnings.title'),
          "standard-input-style": ""
        }, null, 8, ["modelValue", "label"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.report_format')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportFormatInput, { formats: ['html', 'csv', 'pdf', 'excel'] }),
        _createVNode(ReportDataViewSelect),
        _createVNode(ReportPageBreakInput, { term: "carer" })
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}
}

})