import {
  Report, ReportClass,
} from '@/_shared/types/report';
import * as apiReports from '@/_shared/services/reportsApi';
import useUserStore from '@/_shared/store/user';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { defaultReport, getDefaultReport } from '@/reports/helpers/defaultReports';

const useReportStore = defineStore('report', () => {
  const newReport = ref<Report>(defaultReport);
  const reports = ref<Report[]>([]);
  const reportValid = ref(true);

  const buildNewReport = (reportClass: ReportClass): void => {
    newReport.value = getDefaultReport(reportClass, useUserStore().currentOrgUnitId) as Report;
  };

  const fetchAll = async (orgUnitId = useUserStore().currentOrgUnitId): Promise<void> => {
    reports.value = await apiReports.fetchAllReports(orgUnitId);
    if (reports.value.filter((report) => ['waiting', 'processing'].includes(report.state)).length) {
      setTimeout(() => fetchAll(orgUnitId), 5000);
    }
  };

  const fetchReport = async (ids: number[]): Promise<Report> => apiReports.fetchReport(ids[0]);

  const deleteReport = (id: number): void => {
    apiReports.deleteReport(id).then(() => {
      reports.value = reports.value.filter((report) => report.id !== id);
    });
  };

  const createReport = async (): Promise<boolean> => {
    const resp = await apiReports.createReport(newReport.value as Report);
    return !(resp instanceof Error);
  };

  return {
    reports,
    newReport,
    reportValid,
    buildNewReport,
    fetchAll,
    fetchReport,
    deleteReport,
    createReport,
  };
});

export default useReportStore;
