import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-reports-list" }
const _hoisted_2 = { class: "v-report-add-btn" }
const _hoisted_3 = { class: "table_cell_primary" }
const _hoisted_4 = { class: "actions" }
const _hoisted_5 = {
  key: 0,
  class: "action loading-icon"
}
const _hoisted_6 = { class: "action menu-icon" }
const _hoisted_7 = { class: "action item-menu" }

import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { previewReport, downloadReport } from '@/_shared/services/reportsApi';
import { BaseCard, BaseIcon, LoadingSpinner } from '@/_shared/components';
import vClickOutside from '@/_shared/directives/ClickOutside';

import BaseListTable from '@/_shared/components/display/BaseListTable/BaseListTable.vue';
import BaseLandingStrip from '@/_shared/components/display/BaseLandingStrip/BaseLandingStrip.vue';
import BaseDropdownList from '@/_shared/components/general/BaseDropdownList.vue';
import useReportStore from '@/_shared/store/reports';
import BaseSearchText from '@/_shared/components/inputs/BaseSearchText/BaseSearchText.vue';
import { ReportFrontendStatus } from '@/_shared/components/display/BaseListTable/types';
import { Report } from '@/_shared/types/report';
import { useRouter } from 'vue-router';
import { formatAmPmDateTime } from '@/_shared/helpers/useDateTimeHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportList',
  setup(__props) {

const router = useRouter();

const reportStore = useReportStore();
const { reports } = storeToRefs(reportStore);
const showMenu = ref<string | number>(0);

const searchTermRef = ref('');
const formattedReports = computed(() => reports.value.map((r) => ({
  identifier: r.id,
  cells: {
    title: r.title,
    createdAt: r.createdAt.toLocaleString(),
    format: r.format.toUpperCase(),
  },
  status: (r.state === 'ready' ? 'active' : 'neutral') as ReportFrontendStatus,
})));
const filteredReports = computed(() => formattedReports.value.filter((report) => {
  const titleCell = (report.cells.title as string).toLowerCase();
  return titleCell.includes(searchTermRef.value);
}));

const openLauncher = (id: string | number) => {
  if (id !== showMenu.value) {
    showMenu.value = id;
    return;
  }
  showMenu.value = 0;
};

const getReportById = (reportId: number): Report|undefined => reports.value.find((report: Report) => report.id === reportId);

const checkDisableBtn = (reportId: number, defaultStatus: boolean) => {
  const report = getReportById(reportId);

  if (report && ['csv', 'excel'].includes(report.format)) {
    return true;
  }

  return defaultStatus;
};

const getMenuOptions = (menuTitle: string, reportId: number, isVisible = true, isDisabled = false) => [{
  options: [
    {
      show: isVisible,
      disabled: checkDisableBtn(reportId, isDisabled),
      text: 'Preview',
      method: async () => {
        const target = getReportById(reportId);
        await previewReport(target as Report);
      },
    },
    {
      show: isVisible,
      disabled: isDisabled,
      text: 'Download',
      method: async () => {
        const target = reports.value.find((report: Report) => report.id === reportId);
        await downloadReport(target as Report);
      },
    },
    {
      show: isVisible,
      disabled: true,
      text: 'Use as template',
      method: async () => {
        console.log('Functionality not yet available...');
      },
    },
    {
      show: true, // always shown
      disabled: false, // never disabled
      text: 'Delete',
      method: () => {
        reportStore.deleteReport(reportId);
      },
    },
  ],
  title: menuTitle,
}];

const clearShowMenu = (event: Event) => {
  const element = (event.target as HTMLInputElement);
  if (element.tagName !== 'I' && showMenu.value !== 0) {
    showMenu.value = 0;
  }
};

const updateFilterViaSearchTerm = (searchTerm: string) => {
  searchTermRef.value = searchTerm.toLowerCase();
};

const addNewReport = () => {
  router.push('/reports/new');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseLandingStrip, { title: "Vue Reports Page Landing Strip" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(BaseIcon), {
            name: "v-icon-add-new",
            alt: "Add new report",
            size: "3x",
            backgroundColor: "teal",
            border: true,
            onClick: addNewReport
          })
        ]),
        _createVNode(BaseSearchText, {
          onSearch: updateFilterViaSearchTerm,
          inputId: 1,
          additionalStyles: "margin: 22px 0 0 0;"
        }),
        _createVNode(_unref(BaseCard), { title: "Reports" }, {
          header: _withCtx(() => [
            _createVNode(_unref(BaseIcon), {
              name: "v-icon-report-circled",
              size: "3x",
              backgroundColor: "black",
              foregroundColor: "white"
            }),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "v-base-card-title" }, "Reports", -1))
          ]),
          content: _withCtx(() => [
            _createVNode(BaseListTable, {
              displayExtraSlotColumn: "",
              rows: filteredReports.value
            }, {
              "slot-title": _withCtx((slotProps) => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(slotProps.data), 1)
              ]),
              "slot-state": _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("   ")
              ])),
              "slot-createdAt": _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_unref(formatAmPmDateTime)(slotProps.data)), 1)
              ]),
              actions: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_4, [
                  (slotProps.row?.status === 'neutral')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_unref(LoadingSpinner))
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(), _createBlock(_unref(BaseIcon), {
                      name: "v-icon-launcher",
                      onClick: ($event: any) => (openLauncher(slotProps.row?.identifier)),
                      key: `icon-${slotProps.row?.identifier}`
                    }, null, 8, ["onClick"]))
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    (showMenu.value === slotProps.row?.identifier)
                      ? _withDirectives((_openBlock(), _createBlock(BaseDropdownList, {
                          key: `menu-${slotProps.row?.identifier}`,
                          menuSections: getMenuOptions('', +slotProps.row?.identifier, true, slotProps.row?.status !== 'active'),
                          show: true,
                          enableItemSeparator: true,
                          position: "BottomLeft"
                        }, null, 8, ["menuSections"])), [
                          [_unref(vClickOutside), clearShowMenu]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["rows"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})